<template>
  <div class="trent">
    <van-nav-bar
      :title="$t('signaldetail')"
      left-arrow
      :placeholder="true"
      @click-left="$router.push('/community')"
    />
    <div class="trent-content">
      <van-cell-group :border="false">
        <van-cell
          :title="item.Name"
          :label="item.Balance.toFixed(2)"
          class="trent-title"
        >
          <template #default>
            <div style="color: darkgray">
              {{ item.CreateTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "") }}
            </div>
            <div>
              <span :style="{ color: item.AllProfit > 0 ? 'green' : 'red' }">{{
                item.AllProfit.toFixed(2)
              }}</span>
            </div>
          </template>
        </van-cell>
        <div class="trent-chart">
          <depositecharts
            :xdata="formatxdata(item.AllDeposit)"
            :ydata="formatydata(item.AllDeposit)"
            :title="$t('banlancetrend')"
          ></depositecharts>
        </div>
        <van-cell :title="$t('signaldetail')" class="trent-title" />
        <div class="trent-detail">
          <van-cell :title="$t('Balance')" :label="item.Balance + '$'">
            <template #default>
              <div>{{ $t("Equity") }}</div>
              <div>{{ item.Equity + "$" }}</div>
            </template>
          </van-cell>
          <van-cell :title="$t('Deposit')" :label="item.Deposit + '$'">
            <template #default>
              <div>{{ $t("Withdrawal") }}</div>
              <div>{{ item.Withdrawal + "$" }}</div>
            </template>
          </van-cell>
          <van-cell :title="$t('AllProfit')" :label="item.AllProfit + '$'">
            <template #default>
              <div>{{ $t("Profit2") }}</div>
              <div>{{ item.Profit2 + "$" }}</div>
            </template>
          </van-cell>
          <van-cell :title="$t('Profit')" :label="item.Profit + '$'">
            <template #default>
              <div>{{ $t("ProfitPercent") }}</div>
              <div>{{ item.ProfitPercent + "$" }}</div>
            </template>
          </van-cell>
          <van-cell :title="$t('Volume')" :label="item.Volume + '$'">
            <template #default>
              <div>{{ $t("TicketCount") }}</div>
              <div>{{ item.TicketCount + "$" }}</div>
            </template>
          </van-cell>
          <van-cell :title="$t('TicketBuy')" :label="item.TicketBuy + '$'">
            <template #default>
              <div>{{ $t("TicketSell") }}</div>
              <div>{{ item.TicketSell + "$" }}</div>
            </template>
          </van-cell>
          <van-cell
            :title="$t('ProfitCount1')"
            :label="item.ProfitCount1 + '$'"
          >
            <template #default>
              <div>{{ $t("ProfitCount2") }}</div>
              <div>{{ item.ProfitCount2 + "$" }}</div>
            </template>
          </van-cell>
        </div>

        <van-cell :title="$t('tradesummary')" class="trent-title" />
        <div class="trent-detail">
          <van-cell :title="$t('TradeNum')" :label="item.TradeNum">
            <template #default>
              <div>{{ $t("TradeCycle") }}</div>
              <div>{{ item.TradeCycle }}</div>
            </template>
          </van-cell>
          <van-cell
            :title="$t('MaxDrawdown')"
            :label="item.MaxDrawdown.toFixed(2) * 100 + '%'"
          >
            <template #default>
              <div>{{ $t("WeekProfit") }}</div>
              <div>{{ item.WeekProfit.toFixed(2) + "%" }}</div>
            </template>
          </van-cell>
          <van-cell
            :title="$t('MonthProfit')"
            :label="item.MonthProfit.toFixed(2) + '$'"
          >
            <template #default>
              <div>{{ $t("sumProfit") }}</div>
              <div>{{ item.AllProfit.toFixed(2) + "$" }}</div>
            </template>
          </van-cell>
        </div>
        <div class="trent-button">
          <van-button
            round
            block
            type="info"
            size="small"
            color="#13b5b1"
            @click="copy(item.ID)"
            >{{ $t("Copy") }}</van-button
          >
        </div>
      </van-cell-group>
    </div>
    <div class="van-safe-area-bottom"></div>
  </div>
</template>

<script>
import depositecharts from "@/components/depositecharts";
export default {
  components: { depositecharts },
  data() {
    return {
      item: null,
    };
  },
  created() {
    if (this.$route.params.data != undefined) {
      localStorage.setItem(
        "singal_detail",
        JSON.stringify(this.$route.params.data)
      );
    }

    this.item = JSON.parse(localStorage.getItem("singal_detail"));
    console.log(this.item);
  },
  methods: {
    formatxdata(AllDeposit) {
      //console.log("x");
      //console.log(AllDeposit);
      let xdata = new Array();
      if (AllDeposit == null) {
        return xdata;
      }

      var array = AllDeposit.split(",");
      array.forEach((item, index) => {
        var subarray = item.split(":");
        subarray.forEach((element, i) => {
          if (i == 0) xdata.push("");
        });
      });
      return xdata;
    },
    formatydata(AllDeposit) {
      let ydata = new Array();

      if (AllDeposit == null) {
        return ydata;
      }
      var array = AllDeposit.split(",");
      //console.log("y"); console.log(AllDeposit);
      array.forEach((item, index) => {
        var subarray = item.split(":");
        subarray.forEach((element, i) => {
          if (i == 1) ydata.push(parseFloat(element));
        });
      });
      return ydata;
    },
    copy(id) {
      this.$router.push({
        name: "/signal/applycopy",
        query: {
          ID: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.trent >>> .van-nav-bar {
  background-color: #13b5b1;
}
.trent >>> .van-nav-bar__title {
  color: #fff;
}
.trent >>> .van-icon {
  color: #fff !important;
}
.trent-chart {
  padding: 0 16px 20px 0;
}
.trent-detail .van-cell {
  padding: 0 16px;
}
.trent-detail >>> .van-cell__label {
  margin-top: 0;
}
.trent-title >>> span {
  color: #13b5b1;
}
.trent-button {
  padding: 10px 18px;
  margin-top: 20px;
}
</style>